import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'style-loader!angular2-toaster/toaster.css';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map, filter, share } from 'rxjs/operators';
import {Observable} from 'rxjs';

import { Howl } from 'howler';
import * as internal from 'assert';



@Injectable({
  providedIn: 'root',
})
export class ServicioService {
  player: Howl = null;

  private pedidoCollection: AngularFirestoreCollection<any>;
  private pedidos: Observable<any[]>;

  // Desarrollo
  // API_Uri = 'http://localhost:8888/proyectosGIT/gobox-admin/API_REST';
  // API_Uri = 'https://democms.goboxapp.com/API_REST';
  // API_Firebase = 'pedido-dev';

  // Produccion
  API_Uri = 'https://cms.goboxapp.com/API_REST';
  API_Firebase = 'pedido';

  // API_Uri = 'http://localhost/angular/gobox-admin/API_REST';

  sesion = [];
  idPedido: any = 0;

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  usuario: any;
  CantTono: any;

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
    private afs: AngularFirestore,

  ) {
    this.iniciarTonoPedido();

  }

  // funciones firebase.

  getIdSolicitudFirebase(idPedido: any) {
    // console.log('idPedido :', idPedido);
    var pCollection = this.afs.collection<any>(this.API_Firebase, (ref) => ref.where('idPedido', '==' , idPedido ));
    // var pCollection = this.afs.collection<any>('pedido-dev', (ref) => ref.where('idPedido', '==' , idPedido ));
    var pedido = pCollection.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...data};
                  });
      },
      err => {
        console.log('err :', err);
      }
    ));
    return pedido;
  }

  updatePedidosFirebase(pedido: any, idPedido) {
    var pCollection = this.afs.collection<any>(this.API_Firebase, (ref) => ref.where('idPedido', '==' , idPedido ));
    // var pCollection = this.afs.collection<any>('pedido-dev', (ref) => ref.where('idPedido', '==' , idPedido ));

    return pCollection.doc(pedido.id).update(pedido);
  }

  getCollectionFirebase() {

    this.pedidoCollection =  this.afs.collection<any>(this.API_Firebase, (ref) => ref.where('notificarCMS', '==' , '0'));
    this.pedidos = this.pedidoCollection.snapshotChanges().pipe(map(
      actions => {
        let act =  actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...data};
                  });
  
        // return act.filter( item => {
        //   // return (item.sucursal.indexOf(item.sucursal.find(x => x.idSucursal == idSucursal  &&  x.estado == 1)) != -1);
        // });
        console.log('act :', act);
        return act;
      },
      err => {
        console.log('err :', err);
      }
    ));

  }

  getpedidosFirebase() {
    // console.log('this.pedidos :', this.pedidos);
      this.getCollectionFirebase();
      return this.pedidos.pipe(share());
  }

  iniciarTonoPedido() {
    if(this.recuperarSesion() != null) {
      var sesion = this.recuperarSesion();

      this.getpedidosFirebase().subscribe(
        res => {
          if(res.length == 0) {
            console.log('res.length :', res.length);
            if(this.CantTono) {
              if(this.CantTono < res.length) {
                this.ejecutarTono();
                this.CantTono = res.length;
              } else {
                this.CantTono = res.length;
              }
            } else {
              this.ejecutarTono();
              this.CantTono = res.length;
            }
          } else {
            console.log('paso por el else iniciarTonoPedido() ' );
          }
        },
        err => {
          console.log('err :', err);
        }
      );
    }
  }
  //end funciones firebase.

  guardarSesion(sesion) {
    this.sesion = sesion;
    sessionStorage.setItem("currentUser", JSON.stringify(sesion));
    // localStorage.clear();
  }

  recuperarSesion() {
    return JSON.parse(sessionStorage.getItem("currentUser"));
  }

  setIdPedido(idPedido) {
    this.idPedido = idPedido;
  }

  getIdPedido() {
    return this.idPedido;
  }

  insertarpedido(obj: any) {
    return this.http.post(`${this.API_Uri}/insertarpedido`, obj);
  }

  // GB00ADM
  setLoginAdministrador(user: any, password: any) {
    return this.http.get(
      `${this.API_Uri}/setLoginAdministrador/${user}/${password}`
    );
  }
  setUpdatetokenAdmin(user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/setUpdatetokenAdmin/${user}/${token}`
    );
  }
  // GB01ADM
  getAllproveedor() {
    return this.http.get(`${this.API_Uri}/getAllproveedor`);
  }

  // GB02ADM
  getOneproveedor(id: any) {
    return this.http.get(`${this.API_Uri}/getOneproveedor/${id}`);
  }
  // GB03ADM
  postInsertarproveedor(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarproveedor`, obj);
  }
  // GB04ADM
  postModificarproveedor(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarproveedor`, obj);
  }
  // GB05ADM
  postEliminarproveedor(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarproveedor`, obj);
  }

  // GB06ADM
  getAlladministrador() {
    return this.http.get(`${this.API_Uri}/getAlladministrador`);
  }
  getAlladministradorByestado(id : any) {
    return this.http.get(`${this.API_Uri}/getAlladministradorByestado/${id}`);
  }

  // GB07ADM
  getOneadministrador(id: any) {
    return this.http.get(`${this.API_Uri}/getOneadministrador/${id}`);
  }

  // GB08ADM
  postInsertaradministrador(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertaradministrador`, obj);
  }

  // GB09ADM
  postModificaradministrador(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificaradministrador`, obj);
  }

  // GB10ADM
  postEliminaradministrador(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminaradministrador`, obj);
  }

  // GB11ADM
  getAllProductoNoHabilitado(user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getAllProductoNoHabilitado/${user}/${token}`
    );
  }

  // GB12ADM
  postHabilitarProducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postHabilitarProducto`, obj);
  }

  // GB13ADM
  getProductosProveedor(idProveedor: any, user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getProductosProveedor/${idProveedor}/${user}/${token}`
    );
  }

  // GB14ADM
  getAllproductomes(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllproductomes/${user}/${token}`);
  }

  // GB15ADM
  postInsertarProductoMes(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarProductoMes`, obj);
  }

  // GB16ADM
  postEliminarProductoMes(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarproductomes`, obj);
  }

  // GB17ADM
  getAllcategoria(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllcategoria/${user}/${token}`);
  }

  // GB18ADM
  postInsertarCategoria(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarCategoria`, obj);
  }

  // GB19ADM
  postModificarCategoria(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarcategoria`, obj);
  }

  // GB20ADM
  getAllsubcategoria(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllsubcategoria/${user}/${token}`);
  }
  getAllPropiedadesXsubcategoria(user: any, token: any, sub: any) {
    return this.http.get(
      `${this.API_Uri}/getAllPropiedadesXsubcategoria/${user}/${token}/${sub}`
    );
  }

  getAllDetallePropiedadesXsubcategoria(user: any, token: any, sub: any) {
    return this.http.get(
      `${this.API_Uri}/getAllPropiedadesXsubcategoria/${user}/${token}/${sub}`
    );
  }
  // GB21ADM
  postInsertarsubcategoria(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarsubcategoria`, obj);
  }
  postInsertarsubcategoriaPropiedad(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postInsertarSubcategoriaPropiedad2`,
      obj
    );
  }
  postEliminarSubcategoriaPropiedad(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postEliminarSubcategoriaPropiedad`,
      obj
    );
  }
  postEliminardetallepropiedadsubcategoria(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postEliminardetallepropiedadsubcategoria`,
      obj
    );
  }
  postInsertarProducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarProducto`, obj);
  }

  // GB22ADM
  postModificarsubcategoria(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarsubcategoria`, obj);
  }

  // GB23ADM
  getPedidosFechaProveedor(
    idProveedor: any,
    fecha: any,
    user: any,
    token: any
  ) {
    return this.http.get(
      `${this.API_Uri}/getPedidosFechaProveedor/${idProveedor}/${fecha}/${user}/${token}`
    );
  }

  // GB24ADM
  getDetallePedido(idPedido: any, user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getDetallePedido/${idPedido}/${user}/${token}`
    );
  }

  // GB25ADM
  getPedidosActivos(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getPedidosActivos/${user}/${token}`);
  }

  // GB26ADM
  getAlltipovehiculo(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAlltipovehiculo/${user}/${token}`);
  }

  // GB27ADM
  postInsertartipovehiculo(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertartipovehiculo`, obj);
  }

  // GB28ADM
  postModificartipovehiculo(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificartipovehiculo`, obj);
  }

  // GB29ADM
  getAllpropiedad(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllpropiedad/${user}/${token}`);
  }

  // GB30ADM
  postInsertarpropiedad(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarpropiedad`, obj);
  }

  // GB31ADM
  postModificarpropiedad(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarpropiedad`, obj);
  }

  // GB32ADM
  getAlldetallepropiedad(idPropiedad: any, user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getAlldetallepropiedad/${idPropiedad}/${user}/${token}`
    );
  }

  // GB33ADM
  postInsertardetallepropiedad(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertardetallepropiedad`, obj);
  }

  // GB34ADM
  postModificardetallepropiedad(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificardetallepropiedad`, obj);
  }

  // GB35ADM
  getSCDetallePropiedad(idDetallePropiedad: any, user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getSCDetallePropiedad/${idDetallePropiedad}/${user}/${token}`
    );
  }

  // GB36ADM
  getDetallePropiedadSubC(idDetallePropiedad: any, user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getDetallePropiedadSubC/${idDetallePropiedad}/${user}/${token}`
    );
  }
  getDetallePropiedadXSubCategoria(
    idSubcategoria: any,
    idPropiedad: any,
    user: any,
    token: any
  ) {
    return this.http.get(
      `${this.API_Uri}/getDetallePropiedadXSubCategoria/${idSubcategoria}/${idPropiedad}/${user}/${token}`
    );
  }

  // GB37ADM
  postInsertardetallepropiedadsubcategoria(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postInsertardetallepropiedadsubcategoria`,
      obj
    );
  }

  // GB38ADM
  getAllG_noticias(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllNoticias/${idtoken}/${token}`);
  }

  // GB39ADM
  postInsertarG_noticias(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarNoticias`, obj);
  }

  // GB40ADM
  postModificarG_noticias(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarNoticias`, obj);
  }

  // GB41ADM
  postEliminarG_noticias(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarNoticias`, obj);
  }

  // GB42ADM
  getListaSucursalProveedor(idProveedor: any, idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getListaSucursalProveedor/${idProveedor}/${idtoken}/${token}`
    );
  }

  // GB43ADM
  getListaProveedor(idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getListaProveedor/${idtoken}/${token}`
    );
  }

  // GB44ADM
  getListaProductoSucursal(idSucursal: any, idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getListaProductoSucursal/${idSucursal}/${idtoken}/${token}`
    );
  }

  // GB45ADM
  getListaSucursalProveedor2(idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getListaSucursalProveedor2/${idtoken}/${token}`
    );
  }

  // GB46ADM
  getPedidosRechazados(idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getPedidosRechazados/${idtoken}/${token}`);
  }

  // GB47ADM
  getDetallePedidoRechazado(idPedido: any, idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getDetallePedidoRechazado/${idPedido}/${idtoken}/${token}`);
  }

  // GB47ADM
  getAllproductos() {
    return this.http.get(`${this.API_Uri}/getAllproductos`);
  }

  // GB49ADM
  postModificarDetallePedido(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarDetallePedido`, obj);
  }

  // GB50ADM
  postModificarContacto(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarContacto`, obj);
  }

  // GB51ADM
  postModificarPrecioMinimo(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarPrecioMinimo`, obj);
  }

  // GB52ADM
  postModificarRequisitosCond(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarRequisitosCond`, obj);
  }

  // GB53ADM
  getConfiguraciones() {
    return this.http.get(`${this.API_Uri}/getConfiguraciones`);
  }

  // GB54ADM
  getProveedores() {
    return this.http.get(`${this.API_Uri}/getProveedores`);
  }

  // GB55ADM
  getSucursalProveedor(idProveedor: any) {
    return this.http.get(
      `${this.API_Uri}/getSucursalProveedor/${idProveedor}`);
  }

  // GB56ADM
  getProductoSucursal(idSucursal: any) {
    return this.http.get(
      `${this.API_Uri}/getProductoSucursal/${idSucursal}`);
  }

  // GB57ADM
  getPedidoAuxilio() {
    return this.http.get(
      `${this.API_Uri}/getPedidoAuxilio`);
  }

  // GB58ADM
  getConductorDisponibleAux(idTipoVehiculo: any) {
    return this.http.get(
      `${this.API_Uri}/getConductorDisponibleAux/${idTipoVehiculo}`);
  }
  getConductorDisponibleSinFiltro() {
    return this.http.get(
      `${this.API_Uri}/getConductorDisponibleSinFiltro`);
  }

  // GB59ADM
  asignarConductorAux(obj: any) {
    return this.http.post(`${this.API_Uri}/asignarConductorAux`, obj);
  }

  // GB60ADM
  getPedidoAuxilioR(fechaInicio: any, fechaFin: any) {
    return this.http.get(
      `${this.API_Uri}/getPedidoAuxilioR/${fechaInicio}/${fechaFin}`);
  }

  // GB61ADM
  getUbicacionPedidos() {
    return this.http.get(
      `${this.API_Uri}/getUbicacionPedidos`);
  }

  // GB62ADM
  getInfoPedido(idPedido: any) {
    return this.http.get(
      `${this.API_Uri}/getInfoPedido/${idPedido}`);
  }

  // GB63ADM
  postModificarRangoBusqueda(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarRangoBusqueda`, obj);
  }

  // GB64PRO
  getUltimasCalificacionesConductor(idConductor: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getUltimasCalificacionesConductor/${idConductor}/${idtoken}/${token}`);
  }

  // GB65PRO
  getCalificacionesFechasConductor(idConductor: any, fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCalificacionesFechasConductor/${idConductor}/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
  }

  // GB66PRO
  getUltimasCalificacionesProveedor(idProveedor: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getUltimasCalificacionesProveedor/${idProveedor}/${idtoken}/${token}`);
  }

  // GB67PRO
  getCalificacionesFechasProveedor(idProveedor: any, fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCalificacionesFechasProveedor/${idProveedor}/${fechaInicio}/${fechaFin}
    /${idtoken}/${token}`);
  }

  // GB68ADM
  getAllsubcategoria2(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllsubcategoria2/${user}/${token}`);
  }

  // GB69ADM
  postModificarproducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarproducto`, obj);
  }

  // GB70PRO
  getConsolidacionesRevisadas(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getConsolidacionesRevisadas/${idtoken}/${token}`);
  }

  // GB71PRO
  getDetalleConsolidacionAdmin(idConsolidacion: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getDetalleConsolidacionAdmin/${idConsolidacion}/${idtoken}/${token}`);
  }

  // GB72ADM
  postModificarConsolidacionAdmin(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarConsolidacionAdmin`, obj);
  }

  // GB73PRO
  getConsolidacionesRangoAdmin(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getConsolidacionesRangoAdmin/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
  }

  // GB74PRO
  getCompensaciones(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCompensaciones/${idtoken}/${token}`);
  }

  // GB75PRO
  getCompensacionesRango(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCompensacionesRango/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
  }

  // GB76ADM
  postInsertarCompensacion(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarCompensacion`, obj);
  }

  // GB77ADM
  getProveedoresConsolidacion() {
    return this.http.get(`${this.API_Uri}/getProveedoresConsolidacion`);
  }

  // GB78ADM
  getAllProductoModificado(user: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getAllProductoModificado/${user}/${token}`);
  }

  // GB79ADM
  postHabilitarProductoModificado(obj: any) {
    return this.http.post(`${this.API_Uri}/postHabilitarProductoModificado`, obj);
  }

  // GB80PRO
  postModificarPassword(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarPassword` , obj);
  }

  // GB81PRO
  getCantidadNuevosNoValidados(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCantidadNuevosNoValidados/${idtoken}/${token}`);
  }

  // GB82PRO
  getCantidadModificadosNoValidados(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCantidadModificadosNoValidados/${idtoken}/${token}`);
  }

  // GB83PRO
  getPedidosNoFinalizados(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getPedidosNoFinalizados/${idtoken}/${token}`);
  }

  // GB84PRO
  getCantidadPedidosAuxilio(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCantidadPedidosAuxilio/${idtoken}/${token}`);
  }

  // GB85ADM
  postModificarDeudaMaximaConductor(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarDeudaMaximaConductor`, obj);
  }

  // GB86PRO
  getLast20Clientes(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getLast20Clientes/${idtoken}/${token}`);
  }

  // GB87PRO
  getBusquedaCliente(text: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getBusquedaCliente/${text}/${idtoken}/${token}`);
  }
  getBusquedaClienteLight(text: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getBusquedaClienteLight/${text}/${idtoken}/${token}`);
  }
  getBusquedaProveedorLight(text: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getBusquedaProveedorLight/${text}/${idtoken}/${token}`);
  }
  getBusquedaProductoLight(text: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getBusquedaProductoLight/${text}/${idtoken}/${token}`);
  }

  // GB88PRO
  getLast20Conductores(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getLast20Conductores/${idtoken}/${token}`);
  }

  // GB89PRO
  getBusquedaConductor(text: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getBusquedaConductor/${text}/${idtoken}/${token}`);
  }

  // GB90PRO
  getResetPasswordProveedor(idProveedor: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getResetPasswordProveedor/${idProveedor}/${idtoken}/${token}`);
  }

  // GB91ADM
  getListaSolicitudTransportePendiente(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaSolicitudTransportePendiente/${idtoken}/${token}`);
  }

   // GB92ADM
   postAsignarConductorSolicitudTrans(obj: any) {
    return this.http.post(`${this.API_Uri}/postAsignarConductorSolicitudTrans`, obj);
  }

  // GB93ADM
  getIndicadores(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getIndicadores/${idtoken}/${token}`);
  }

  // GB94ADM - POST - OBTENER INDICADORES
  postCancelarPedido(obj: any) {
    return this.http.post(`${this.API_Uri}/postCancelarPedido`, obj);
  }

  // GB95ADM - POST - CANCELAR PEDIDO PARCIALMENTE
  postCancelarPedidoParcial(obj: any) {
    return this.http.post(`${this.API_Uri}/postCancelarPedidoParcial`, obj);
  }

  // GB96ADM - POST - INSERTAR COMERCIO
  postRegistrarComercio(obj: any) {
    return this.http.post(`${this.API_Uri}/postRegistrarComercio`, obj);
  }

  // GB97ADM - POST - MODIFICAR COMERCIO
  postModificarComercio(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarComercio`, obj);
  }

  // GB98ADM - POST - CAMBIAR ESTADO DE COMERCIO
  postCambiarEstadoComercio(obj: any) {
    return this.http.post(`${this.API_Uri}/postCambiarEstadoComercio`, obj);
  }

  // GB99ADM - GET - OBTENER LISTA DE COMERCIOS
  getListaComercios(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaComercios/${idtoken}/${token}`);
  }

  // GB100ADM - POST - INSERTAR USUARIO DE COMERCIO
  postRegistrarUsuarioComercio(obj: any) {
    return this.http.post(`${this.API_Uri}/postRegistrarUsuarioComercio`, obj);
  }

  // GB101ADM - POST - RESETEAR PASSWORD DE USUARIO DE COMERCIO
  postResetPassUsuarioComercio(obj: any) {
    return this.http.post(`${this.API_Uri}/postResetPassUsuarioComercio`, obj);
  }

  // GB102ADM - GET - OBTENER LISTA DE USUARIOS DE COMERCIO
  getListaUsuariosComercio(idtoken: any, token: any, idComercio: any) {
    return this.http.get(`${this.API_Uri}/getListaUsuariosComercio/${idtoken}/${token}/${idComercio}`);
  }

  // GB103ADM - GET - OBTENER LISTA DE SOLICITUDES PENDIENTES DE PAGO DE COMERCIO
  getListaSolicitudPendientePagoComercio(idtoken: any, token: any, idComercio: any) {
    return this.http.get(`${this.API_Uri}/getListaSolicitudPendientePagoComercio/${idtoken}/${token}/${idComercio}`);
  }

  // GB104ADM - POST - MARCAR SOLICITUD DE COMERCIO COMO PAGADA
  postPagarSolicitudComercio(obj: any) {
    return this.http.post(`${this.API_Uri}/postPagarSolicitudComercio`, obj);
  }

  // GB105ADM - GET - OBTENER LISTA DE COMERCIOS CON PAGOS PENDIENTES
  getListaComercioPagoPendiente(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaComercioPagoPendiente/${idtoken}/${token}`);
  }

  // GB106ADM - GET - OBTENER LISTA DE MATERIALES
  getListaMateriales(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaMateriales/${idtoken}/${token}`);
  }

  // GB107ADM - GET - OBTENER LISTA DE PRODUCTOS POR MATERIAL
  getListaMaterialProducto(idtoken: any, token: any, idMaterial: any) {
    return this.http.get(`${this.API_Uri}/getListaMaterialProducto/${idtoken}/${token}/${idMaterial}`);
  }

  // GB108ADM - POST - INSERTAR MATERIAL
  postRegistrarMaterial(obj: any) {
    return this.http.post(`${this.API_Uri}/postRegistrarMaterial`, obj);
  }

  // GB109ADM - POST - ELIMINAR MATERIAL
  postEliminarMaterial(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarMaterial`, obj);
  }

  // GB110ADM - POST - MODIFICAR MATERIAL
  postModificarMaterial(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarMaterial`, obj);
  }

  // GB111ADM - POST - ELIMINAR PRODUCTO MATERIAL
  postEliminarMaterialProducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarMaterialProducto`, obj);
  }

  // GB112ADM - POST - ELIMINAR PRODUCTO MATERIAL SUGERIDO
  postEliminarMaterialProductoSugerido(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarMaterialProductoSugerido`, obj);
  }

  // GB113ADM - POST - INSERTAR MATERIAL PRODUCTO
  postRegistrarMaterialProducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postRegistrarMaterialProducto`, obj);
  }

  // GB114ADM - POST - INSERTAR MATERIAL PRODUCTO SUGERIDO
  postRegistrarMaterialProductoSugerido(obj: any) {
    return this.http.post(`${this.API_Uri}/postRegistrarMaterialProductoSugerido`, obj);
  }

  // GB115ADM - GET - OBTENER HISTORIAL DE PAGOS DE SOLICITUD POR COMERCIO Y FECHAS
  getHistorialPagoComercio(idtoken: any, token: any, idComercio: any, fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getHistorialPagoComercio/${idtoken}/${token}/${idComercio}/${fechaInicio}/${fechaFin}`);
  }

  // GB116ADM - POST - MODIFICAR MATERIAL PRODUCTO
  postModificarMaterialProducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarMaterialProducto`, obj);
  }

  // GB117ADM - POST - MODIFICAR MATERIAL PRODUCTO SUGERIDO
  postModificarMaterialProductoSugerido(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarMaterialProductoSugerido`, obj);
  }

  // GB118ADM - GET - OBTENER LISTA DE PRODUCTOS (ID Y NOMBRE)
  getAllproductosNombre() {
    return this.http.get(`${this.API_Uri}/getAllproductosNombre`);
  }

  // GB119ADM - GET - OBTENER LISTA DE PROVEEDORES (SÓLO NOMBRE)
  getListaProveedorNombre() {
    return this.http.get(`${this.API_Uri}/getListaProveedorNombre`);
  }

  // GB120ADM - GET - OBTENER HISTORIAL DE PAGOS DE SOLICITUD POR COMERCIO Y FECHAS
  getAllProductoProveedor(idComercio: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllProductoProveedor/${idComercio}/${idtoken}/${token}`);
  }

  // GB121ADM - POST - MODIFICAR PRODUCTO (DATOS DIMENSIONES)
  postModificarProductoDimensiones(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarProductoDimensiones`, obj);
  }

  // GB122ADM - POST - MODIFICAR ESTADO PEDIDO
  postModificarEstadoPedidoAdm(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarEstadoPedidoAdm`, obj);
  }

  // GB123ADM - GET - OBTENER LISTA DE CONDUCTORES
  getAllConductorAdm(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllConductorAdm/${idtoken}/${token}`);
  }
  // GB123ADM - GET - OBTENER LISTA DE CONDUCTORES
  getAllEstadosPedido() {
    return this.http.get(`${this.API_Uri}/getAllestadoPedido`);
  }

  // GB124ADM - POST - MODIFICAR PASSWORD GLOBAL
  postModificarPasswordGlobal(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarPasswordGlobal`, obj);
  }

  // GB125ADM - POST - INSERTAR FERRETERIA
  postInsertarFerreteria(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarFerreteria`, obj);
  }

  // GB126ADM - POST - MODIFICAR FERRETERIA
  postModificarFerreteria(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarFerreteria`, obj);
  }

  // GB127ADM - POST - ELIMINAR FERRETERIA
  postEliminarFerreteria(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarFerreteria`, obj);
  }

  // GB128ADM - GET - OBTENER LISTA DE FERRETERIAS
  getAllFerreteria() {
    return this.http.get(`${this.API_Uri}/getAllFerreteria`);
  }

  // GB129ADM - GET - OBTENER LISTA DE CLIENTES FERRETEROS
  getAllClientesFerreteros() {
    return this.http.get(`${this.API_Uri}/getAllClientesFerreteros`);
  }
  getAllClientes() {
    return this.http.get(`${this.API_Uri}/getAllClientes`);
  }

  // GB130ADM - GET - OBTENER REPORTE DE PROVEEDORES
  getReporteProveedores(fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getReporteProveedores/${fechaInicio}/${fechaFin}`);
  }

  // GB131ADM - GET - OBTENER REPORTE DE CONDUCTORES
  getReporteConductores(fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getReporteConductores/${fechaInicio}/${fechaFin}`);
  }

  ////////////////////////////////////////////////////////

  getAllpromocion() {
    return this.http.get(this.API_Uri + `/getAllpromocion`);
  }

  getOnepromocion(id: any) {
    return this.http.get(`${this.API_Uri}/getOnepromocion/${id}`);
  }

  postInsertarpromocion(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarpromocion`, obj);
  }

  postModificarpromocion(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarpromocion`, obj);
  }

  postEliminarpromocion(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarpromocion`, obj);
  }

  ////////////////////////////////////////////////////////
  getAllpublicidad() {
    return this.http.get(`${this.API_Uri}/getAllpublicidad`);
  }
  getOnepublicidad(id: any) {
    return this.http.get(`${this.API_Uri}/getOnepublicidad/${id}`);
  }
  postInsertarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarpublicidad`, obj);
  }
  postModificarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarpublicidad`, obj);
  }
  postEliminarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarpublicidad`, obj);
  }
  ///////////////////////////////////////////////////

  getAllcalificacion() {
    return this.http.get(`${this.API_Uri}/getAllcalificacion`);
  }

  getOnecalificacion(id: any) {
    return this.http.get(`${this.API_Uri}/getOnecalificacion/${id}`);
  }

  postInsertarcalificacion(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarcalificacion`, obj);
  }

  postModificarcalificacion(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarcalificacion`, obj);
  }

  postEliminarcalificacion(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarcalificacion`, obj);
  }

  getAllcliente(idtoken: any, token: any, f1: any, f2: any) {
    return this.http.get(
      `${this.API_Uri}/getAllcliente/${idtoken}/${token}/${f1}/${f2}`
    );
  }
  getHistorialPedidos(idtoken: any, token: any, idcliente: any) {
    return this.http.get(
      `${this.API_Uri}/getHistorialPedidos/${idtoken}/${token}/${idcliente}`
    );
  }
  getDireccionesCliente( idcliente: any) {
    return this.http.get(
      `${this.API_Uri}/getDireccionesCliente/${idcliente}`
    );
  }
  postInsertarDireccionCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarDireccionCliente`, obj);
  }
  postEliminarDireccionCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarDireccionCliente`, obj);
  }

  getActividades(idcliente: any, inicio: any, cantidad: any) {
    return this.http.get(
      `${this.API_Uri}/getActividades/${idcliente}/${inicio}/${cantidad}`
    );
  }
  getActividadesProveedor(idproveedor: any, inicio: any, cantidad: any) {
    return this.http.get(
      `${this.API_Uri}/getActividadesProveedor/${idproveedor}/${inicio}/${cantidad}`
    );
  }
  getAllTags() {
    return this.http.get(
      `${this.API_Uri}/getAllTags`
    );
  }
  getActividadesByuser(idUsuario: any, inicio: any, cantidad: any) {
    return this.http.get(
      `${this.API_Uri}/getActividadesByuser/${idUsuario}/${inicio}/${cantidad}`
    );
  }
  getCambiarEstadoCliente(idcliente: any, estado: any) {
    return this.http.get(
      `${this.API_Uri}/getCambiarEstadoCliente/${idcliente}/${estado}`
    );
  }
  postInsertarcliente(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postInsertarcliente`,
      obj
    );
  }
  postModificarcliente(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postModificarcliente`,
      obj
    );
  }
  postInsertarActividad(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postInsertarActividad`,
      obj
    );
  }
  postModificarActividad(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postModificarActividad`,
      obj
    );
  }

  //conductor
  getAllconductor(idtoken: any, token: any, fi: any, ff: any) {
    return this.http.get(
      `${this.API_Uri}/getAllconductor/${idtoken}/${token}/${fi}/${ff}`
    );
  }
  getAllconductorActivos(idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getAllconductorActivos/${idtoken}/${token}`
    );
  }
  getFerreterosPorValidar(idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getFerreterosPorValidar/${idtoken}/${token}`
    );
  }
  setValidarFerreteros(id: any) {
    return this.http.get(
      `${this.API_Uri}/setValidarFerreteros/${id}`
    );
  }
  getAllconductorFiltro(idtoken: any, token: any, filtro: any) {
    return this.http.get(
      `${this.API_Uri}/getAllconductorFiltro/${idtoken}/${token}/${filtro}`
    );
  }

  getAlltransaccionconductor(idconductor: any, fi: any, ff: any) {
    return this.http.get(
      `${this.API_Uri}/getAlltransaccionconductor/${idconductor}/${fi}/${ff}`
    );
  }

  postInsertartransaccionconductor(obj: any) {
    return this.http.post(
      `${this.API_Uri}/postInsertartransaccionconductor`,
      obj
    );
  }

  getOneconductor(id: any, idtoken: any, token: any) {
    return this.http.get(
      `${this.API_Uri}/getOneconductor/${id}/${idtoken}/${token}`
    );
  }

  postInsertarconductor(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarconductor`, obj);
  }

  postModificarconductor(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarconductor`, obj);
  }

  postEliminarconductor(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarconductor`, obj);
  }

  getAllciudad() {
    return this.http.get(`${this.API_Uri}/getAllciudad`);
  }

  getOnevehiculo(id: any) {
    return this.http.get(`${this.API_Uri}/getOnevehiculo/${id}`);
  }

  postInsertarvehiculo(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarvehiculo`, obj);
  }

  postModificarvehiculo(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarvehiculo`, obj);
  }

  //presupuesto

  
  getAllpresupuestoPendiente(user: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllpresupuestoPendiente/${user}/${token}`);
  }
  getAllpresupuestoPendientePorFecha(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getAllpresupuestoPendientePorFecha/${user}/${token}/${fi}/${ff}`);
  }
  postInsertarPresupuesto(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarPresupuesto`, obj);
  }
  setCambioEstado(id: any, estado: any) {
    return this.http.get(`${this.API_Uri}/setCambioEstado/${id}/${estado}`);
  }

  //reportes 

  
  getReporte11(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte11/${user}/${token}/${fi}/${ff}`);
  }
  getReporte21(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte21/${user}/${token}/${fi}/${ff}`);
  }
  getReporte22(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte22/${user}/${token}/${fi}/${ff}`);
  }
  getReporte23(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte23/${user}/${token}/${fi}/${ff}`);
  }
  getReporte24(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte24/${user}/${token}/${fi}/${ff}`);
  }
  getReporte31(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte31/${user}/${token}/${fi}/${ff}`);
  }
  getReporte41(user: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/getReporte41/${user}/${token}/${fi}/${ff}`);
  }
  getDetalleRegistroObra(id: any) {
    return this.http.get(`${this.API_Uri}/getDetalleRegistroObra/${id}`);
  }
  postUpdateRegistroObra(obj: any) {
    return this.http.post(`${this.API_Uri}/postUpdateRegistroObra`, obj);
  }
  getAllTipoObra() {
    return this.http.get(`${this.API_Uri}/getAllTipoObra`);
  }
  wsElimnarObra(id: any) {
    return this.http.get(`${this.API_Uri}/wsElimnarObra/${id}`);
  }
  // catalogo

  getCatalogos( ) {
    return this.http.get(`${this.API_Uri}/getCatalogos`);
  }
  postCatalogo(obj: any) {
    return this.http.post(`${this.API_Uri}/postCatalogo`, obj);
  }

  //local

  setUsuario(obj) {
    this.usuario = obj;
  }
  getUsuario() {
    return this.usuario;
  }

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, `${titleContent}`, config);
  }

  numerosaLetras(cantidad) {

    let numero: any = 0;
    cantidad = parseFloat(cantidad).toFixed(2);

    if (cantidad == "0.00" || cantidad == "0") {
        return "CERO con 00/100 ";
    } else {
        var ent = cantidad.toString().split(".");
        var arreglo = this.separar_split(ent[0]);
        var longitud = arreglo.length;
        
        switch (longitud) {
            case 1:
                numero = this.unidades(arreglo[0]);
                break;
            case 2:
                numero = this.decenas(arreglo[0], arreglo[1]);
                break;
            case 3:
                numero = this.centenas(arreglo[0], arreglo[1], arreglo[2]);
                break;
            case 4:
                numero = this.unidadesdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3]);
                break;
            case 5:
                numero = this.decenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4]);
                break;
            case 6:
                numero = this.centenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5]);
                break;
        }

        ent[1] = isNaN(ent[1]) ? '00' : ent[1];

        return numero + " con " + ent[1] + "/100";
    }
}

unidades(unidad) {
    var unidades = Array('UN ','DOS ','TRES ' ,'CUATRO ','CINCO ','SEIS ','SIETE ','OCHO ','NUEVE ');
   

    return unidades[unidad - 1];
}

 
decenas(decena, unidad) {
    var diez = Array('ONCE ','DOCE ','TRECE ','CATORCE ','QUINCE' ,'DIECISEIS ','DIECISIETE ','DIECIOCHO ','DIECINUEVE ');
    var decenas = Array('DIEZ ','VEINTE ','TREINTA ',' CUARENTA ',' CINCUENTA ',' SESENTA ','SETENTA ','OCHENTA ','NOVENTA ');
    var veinte = '';
    if (decena ==0 && unidad == 0) {
        return "";
    }

    if (decena == 0 && unidad > 0) {
        return this.unidades(unidad);
    }

    if (decena == 1) {
        if (unidad == 0) {
            return decenas[decena -1];
        } else {
            return diez[unidad -1];
        }
    } else if (decena == 2) {
        if (unidad == 0) {
            return decenas[decena -1];
        }
        else if (unidad == 1) {
            return veinte = "VEINTI" + "UNO";
        } 
        else {
            return veinte = "VEINTI" + this.unidades(unidad);
        }
    } else {

        if (unidad == 0) {
            return decenas[decena -1] + " ";
        }
        if (unidad == 1) {
            return decenas[decena -1] + " Y " + "UNO";
        }

        return decenas[decena -1] + " Y " + this.unidades(unidad);
    }
}

  centenas(centena, decena, unidad) {
    var centenas = Array( "CIENTO ", "DOSCIENTOS ", "TRESCIENTOS ", "CUATROCIENTOS ","QUINIENTOS ","SEISCIENTOS ","SETECIENTOS ", "OCHOCIENTOS ","NOVECIENTOS ");

    if (centena == 0 && decena == 0 && unidad == 0) {
        return "";
    }
    if (centena == 1 && decena == 0 && unidad == 0) {
        return "CIEN ";
    }

    if (centena == 0 && decena == 0 && unidad > 0) {
        return this.unidades(unidad);
    }

    if (decena == 0 && unidad == 0) {
        return centenas[centena - 1]  +  "" ;
    }

    if (decena == 0) {
        var numero = centenas[centena - 1] + "" + this.decenas(decena, unidad);
        return numero.replace(" Y ", " ");
    }
    if (centena == 0) {

        return  this.decenas(decena, unidad);
    }
     
    return centenas[centena - 1]  +  "" + this.decenas(decena, unidad);
    
}

  unidadesdemillar(unimill, centena, decena, unidad) {
    var numero = this.unidades(unimill) + " MIL " + this.centenas(centena, decena, unidad);
    numero = numero.replace("UN  MIL ", "MIL " );
    if (unidad == 0) {
        return numero.replace(" Y ", " ");
    } else {
        return numero;
    }
}

 decenasdemillar(decemill, unimill, centena, decena, unidad) {
    var numero = this.decenas(decemill, unimill) + " MIL " + this.centenas(centena, decena, unidad);
    return numero;
}

 centenasdemillar(centenamill,decemill, unimill, centena, decena, unidad) {

    var numero : any = 0;
    numero = this.centenas(centenamill,decemill, unimill) + " MIL " + this.centenas(centena, decena, unidad);
    
    return numero;
}

 separar_split(texto){
    var contenido = new Array();
    for (var i = 0; i < texto.length; i++) {
        contenido[i] = texto.substr(i,1);
    }
    return contenido;
}


ejecutarTono() {
  console.log('se ejecuto el tono.' );
  this.player = new Howl({
    src: ['./assets/ringtones/gobox.mp3']
  });
  debugger
  this.player.play();
  this.player.loop(4);


  setTimeout(()=> {
      alert("Nuevo pedido ha llegado");

      this.player.stop();

  }, 1500);
}

}
