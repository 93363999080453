import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Creado por <b><a href="https://clanbolivia.com" target="_blank">Clan Bolivia</a></b> 2023</span>
    <!-- <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
    </div> -->
  `,
})
export class FooterComponent {
}
