import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ServicioService } from '../../servicio/servicio.service';

@Component({
  selector: 'ngx-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
})
export class ModalFormComponent implements OnInit {

  tableS: any = {};
  sesion: any = {};
  idtoken: any = 0;
  token: any = 0;
  passAct: any = 0;
  idTipoUsuario: any = 0;
  loading: any = false;

  constructor(protected ref: NbDialogRef<ModalFormComponent>, private servicio: ServicioService) { }

  ngOnInit() {
    this.sesion = this.servicio.recuperarSesion();
    this.idtoken = this.sesion.idAdministrador;
    this.token = this.sesion.token;
    this.passAct = this.sesion.password;
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    // this.ref.close(value);
    this.loading = true;
    if (this.tableS.passNew == this.tableS.passNew2) {
      this.tableS.idtoken = this.idtoken;
      this.tableS.token = this.token;
      this.tableS.passAct = this.passAct;
      console.log(this.tableS.idTipoUsuario);
      this.servicio.postModificarPassword(this.tableS).subscribe(
        res => {
            if (res['errorCode'] === 2 ) {
                this.servicio.showToast('warning', 'Notificación', 'No hay datos en la tabla');
            } else if (res['errorCode'] === 0 ) {
                // console.log("res['msg'] :" ,res['msg']);
                this.servicio.showToast('success', 'Notificación', 'Contraseña modificada');
                // console.log(res);
                this.sesion.password = res['pass'];
                this.servicio.guardarSesion(this.sesion);
                this.sesion = this.servicio.recuperarSesion();
                this.ref.close();
            } else  if (res['errorCode'] === 4 ) {
              this.servicio.showToast('warning', 'Notificación', 'Su contraseña actual es incorrecta');
          }
          this.loading = false;
        },
        err => {
          console.log(err);
          this.loading = false;
            this.servicio.showToast('warning', 'Notificación', 'Ocurrio un error al obtener los datos');
        },
    );
    } else {
      this.servicio.showToast('danger', 'Notificación', 'La contraseña nueva no coincide');
      this.loading = false;
    }
  }

}
