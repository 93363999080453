import { ServicioService } from '../servicio/servicio.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  sesion: any = {};
  loading = false;

  alerta: any;
  tipoAlerta: any;

  constructor(private Api: ServicioService, private router: Router) { }

  ngOnInit() {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
    this.logout();
  }

  loginUser(event) {
    event.preventDefault();
    const target = event.target;
    const username = target.querySelector('#username').value;
    const password = target.querySelector('#password').value;

    if( username == '' || username == undefined || password == '' || password == undefined){
            this.alerta = "Debe ingresar usuario y contraseña";
            this.tipoAlerta = "danger"
    }else{
      this.loading = true;

      this.Api.setLoginAdministrador(username, password).subscribe(data => {
        const code = data['errorCode'];
        console.log(data);
        if (code === 0) {
            this.alerta = "Datos verificados";
            this.tipoAlerta = "success";
            this.router.navigateByUrl('pages/panel');

            this.Api.setUsuario(data['msg']);

            if(localStorage.getItem('gobox_idplayer') != undefined){
              //guardar token push
              this.Api.setUpdatetokenAdmin(data['msg'].idAdministrador, localStorage.getItem('gobox_idplayer')).subscribe(data => {
                console.log(data);
              });
            }
        } else {
            // this.Api.showToast('danger','Notificación','Ocurrio un error, no pudimos validar tus datos');
            this.alerta = "Ocurrio un error, no pudimos validar tus datos";
            this.tipoAlerta = "danger";
            
        }

        this.loading = false;
        this.Api.guardarSesion(data['msg']);
        // this.Api.guardarDatos(username);
      });
    }
    // this.Api.guardarSesion(data.msg);
  }

  logout() {
    sessionStorage.clear();
  }

}
